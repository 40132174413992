*:focus {
    box-shadow: none !important;
}
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 1.38;
    padding: 8px 15px 5px;
    white-space: normal;    
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    min-height: 46px;
    min-width: 130px;
    border: 1px solid transparent;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
}
.btn.full-width {
    width: 100%;
}
.btn.big-btn {
    font-size: 24px;
}
.btn.black-bg {
    background-color: #000000 !important;
    color: #ffffff !important;
    border-color: #000 !important;
}
.btn.black-bg:hover {
    color: #000000 !important;
    background-color: #ffffff !important;
}
.btn.white-border {
    background-color: #000000 !important;
    color: #ffffff !important;
    border-color: #fff !important;
}
.btn.white-border:hover {
    color: #000000 !important;
    background-color: #ffffff !important;
}
.btn.secondary-bg {
    background-color: #fff !important;
    color: #000000 !important;
    border-color: #000 !important;
}
.btn.secondary-bg:hover {
    color: #fff !important;
    background-color: #000000 !important;
}
.btn.transparent-bg {
    background-color: transparent !important;
    color: #ffffff !important;
    border-color: #fff !important;
}
.btn.transparent-bg:hover {
    background-color: #fff !important;
    color: #231f20 !important;
    border-color: #fff !important;
}
.btn.gray-bg {
    background-color: #2d2c2c !important;
    color: #fff !important;
    border-color: #fff !important;
}
.btn.gray-bg:hover {
    color: #2d2c2c !important;
    background-color: #fff !important;
}
.btn.green-bg {
    color: #fff !important;
    background-color: #2dc02c !important;
    border-color: #fff !important;
}
.btn.orange-bg {
    color: #fff !important;
    background-color: #ff8700 !important;
    border-color: #fff !important;
}
.btn.yellow-bg {
    color: #fff !important;
    background-color: #FA5CBB !important;
    border-color: #fff !important;
}
.btn.red-bg{
    color: #fff !important;
    background-color: #c52c2c !important;
    border-color: #fff !important;
}
.form-group label {
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 5px;
    color: #fff;
}
.reg-label {
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 5px;
}
.form-group.with-label label {
    display: block !important;
}
.form-group {
    margin: 0 0 23px;
}
.form-group .form-control {
    border: 1px solid #e6e7e8;
    background-color: #e6e7e8;
    color: #000;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 18px;
    line-height: 1.25;
    padding: 8px 10px 5px;    
    height: 46px;
}
.form-group select.form-control{
    height: 46px !important;
}
.form-group .form-control::placeholder {
    color: #000;
    opacity: 1;
    font-style: italic;
}
.form-group textarea.form-control {
    min-height: 46px;
    padding: 11px 10px;
}
.form-group .form-control[type="password"]:not(:placeholder-shown) {
    font: large Verdana,sans-serif;
    letter-spacing: 1px;
}
.form-group .form-select {
    border: 1px solid #e6e7e8;
    background-color: #e6e7e8;
    color: #000;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 18px;
    line-height: 1.25;
    padding: 5px 15px;    
    height: 46px;
}
.form-group .form-select::placeholder {
    color: #000;
    opacity: 1;
    font-style: italic;
}
.form-group .form-select option:first-child {
    color: #000;
    opacity: 1;
}
.form-group .form-control .__PrivateStripeElement,
.form-group .form-control .__PrivateStripeElement iframe {
    height: 100% !important;
}
.black-bg:hover .status-select {
    color: #000000;
}
.status-select {
    border: transparent;
    background: transparent;
    color: #fff;
    font-size: 18px;    
    text-transform: uppercase;
    margin-top: -1px;
}
.status-select option {
    color: #000000;
}
.custom-dropdown-wrapper.btn.gray-bg {
    padding: 0 15px;
    background-color: #2d2c2c !important;
    color: #fff !important;
    border-color: #fff !important;
}
.dropdown .btn.gray-bg {
    background-color: #2d2c2c !important;
    color: #fff !important;
    border-color: #fff !important;
    white-space: nowrap;
}
.dropdown .btn.gray-bg.active{
    background-color: #575354 !important;
}
.btn.dropdown-toggle {
    justify-content: space-between;
    width: 100%;
}
.btn.dropdown-toggle::after {
    transform: rotate(-90deg);
    transition: transform 0.3s;
    margin-left: 10px;
    border-top-width: 8px;
    border-right-width: 8px;
    border-left-width: 8px;
}
.btn.dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(0deg);
}
.dropdown .dropdown-menu {
    padding: 0;
    overflow: hidden;
    width: 100%;
    margin: 0;
    box-shadow: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
}
.dropdown-menu .dropdown-item {
    padding: 0;
    background-color: transparent !important;
}
.dropdown .dropdown-menu .btn {
    width: 100%;
}
.custom-select {
    background: transparent;
    border: none !important;
    padding: 0;
    cursor: pointer;
}
.custom-select [class*="-control"] {
    box-shadow: none !important;
    background-color: transparent !important;
    border: none !important;
    min-width: 170px;
    text-align: left;
    height: 100%;
}
.custom-select [class*="-menu"] {
    top: 100%;
    border-radius: 0;
    overflow: hidden;
    z-index: 1001;
    margin: 2px 0;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
}
.custom-select [class*="-menu"] [class*="-MenuList"] {
    padding-top: 0;
    padding-bottom: 0;
}
.custom-select [class*="-menu"] [class*="-option"] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 1.38;
    padding: 8px 15px;
    white-space: normal;    
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    min-height: 46px;
    min-width: 130px;
    border: 1px solid #fff !important;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    cursor: pointer;
    background-color: #2d2c2c !important;
    color: #fff !important;
    white-space: nowrap;
}
.custom-select [class*="-menu"] [class*="-option"].active{
    background-color: #575354 !important;
}
.custom-select[class*="-container"] {
    height: 100%;
}
.custom-select [class*="-indicatorSeparator"] {
    display: none;
}
.custom-select [class*="-indicatorContainer"] svg {
    display: none;
} 
.custom-select [class*="-indicatorContainer"]{
    padding: 0;
    margin-left: 10px;
}
.custom-select [class*="-indicatorContainer"]::after {
    display: inline-block;
    width: 0;
    height: 0;   
    vertical-align: 0.255em;
    content: "";
    border-top: 8px solid #fff;
    border-right: 8px solid transparent;
    border-bottom: 0;
    padding: 0;
    border-left: 8px solid transparent;
    transition: transform 0.3s;
}
#react-select-2-input {
    color: #fff !important;
}
[class*="-singleValue"],
[class*="-placeholder"] {
    color: #fff !important;
}
.required {
    color: #c52c2c;
    margin-left: 5px;
}
textarea.form-control {
    max-width: 100%;
    width: 100%;
    border: none;
    resize: none;
    background: #e6e7e8 !important;
    padding: 4px 8px;
    font-size: 16px;
    line-height: 1.38;
    color: #000;
    border-radius: 10px;
}
.form-group label em {
    color: #c52c2c;
    margin-left: 2px;
}
div#react-select-2-listbox>div {
    padding: 0;
}
.form-check-input:checked{
    background-color: #231f20;
    border-color: #231f20;
}

#sign-form-check:checked{
    border-color: #ffffff !important;
    
}
#sign-form-check{
    margin:0 ;
    margin-top: 3px;
    position: relative;

}

/*Reset css*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
/*General css*/
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  overflow-x: hidden;
  margin: 0;
  font-family: 'Octarine' , sans-serif; 
}
a,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-style: normal;
}
.wrapper {
  min-height: 100%;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:focus {
  outline: none !important;
  border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
a,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}
img {
  vertical-align: middle;
  object-fit: cover;
  border-style: none;
  max-width: 100%;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
em,
i {
  font-style: normal;
  display: inline-block;
}
strong {
  font-weight: bold;
}
.direction-column{
  flex-direction: column;
  cursor: pointer;
}
.margin-left-auto{
  margin-left: auto;
}

body * {
  font-family: "OctarineBold", sans-serif !important;
}
body {
  margin: 0;
  padding: 0;
}
.main-wrap {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  position: relative;
  background-color: #000;
  font-family: "OctarineBold", sans-serif !important;
  color: #fff;
}
.justify-space-between {
  justify-content: space-between;
}
h1 {
  font-size: 38px;
  line-height: 1.28;
}
h2 {
  font-size: 30px;
  line-height: 1.07;
}
h4 {
  font-size: 18px;
  line-height: 1.38;
}
.bg-img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.bg-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.primary-link {
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}
.primary-link::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 1px;
  left: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.primary-link:hover {
  color: #fff;
}
.primary-link:hover::after {
  width: 0;
}
/* sidebar */
.sidebar {
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  flex: 0 0 441px;
  max-width: 441px;
  background-color: #000000;
  z-index: 8;
}
.admin-sidebar.sidebar {
  flex: 0 0 320px;
  max-width: 320px;
  width: 100%;
}
.admin-sidebar.sidebar .overlay {
  display: none;
}
.admin-sidebar .logo-wrap {
  padding: 20px 50px;
  max-width: 100%;
}
.sidebar .bg-img {
  opacity: 0.8;
  top: 70px;
}
.sidebar .sidebar-wrap {
  z-index: 1;
  position: relative;
}
.sidebar .sidebar-wrap .logo-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 29px 50px 42px;
  max-width: 100%;
}
.sidebar .sidebar-wrap .logo-wrap i img {
  display: block;
  width: 100%;
  height: 100%;
}
.sidebar .sidebar-wrap .logo-wrap span {
  color: #fff;
  font-size: 20px;
  line-height: 1.2;
}
.sidebar .sidebar-wrap .menu-list {
  padding: 0 0 30px;
}
.sidebar .sidebar-wrap .menu-list li.active a,
.sidebar .sidebar-wrap .menu-list li a:hover {
  background-color: #121212;
}
.sidebar .sidebar-wrap .menu-list li a {
  padding: 18px 60px;
  font-size: 25px;
  line-height: 1.2;
  color: #ffffff;
  display: block;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  margin: 0 0 8px;
}
/* logo footer */
.logo-footer {
  padding: 44px 0;
  text-align: center;
  background-color: #000;
  position: relative;
  z-index: 1;
  width: 100%;
  border-top: 1px solid #333;
}
.logo-footer .logo-wrap {
  max-width: 220px;
  width: 100%;
  margin: 0 auto;
  display: block;
}
.logo-footer img {
  display: inline-block;
}
/* right block */
.right-content-wrap {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 95px 30px 30px;
  position: relative;
}
.right-content-wrap .title-block {
  margin: 0 0 10px;
  border-radius: 10px;
  padding: 5px 10px;
}
.light-blue-bg {
  background-color: #59d9ff;
}
.blue-bg {
  background-color: #33b1fb;
}
.green-bg {
  background-color: #51efcc;
}
.purple-bg {
  background-color: #c951f0;
}
.yellow-bg {
  background-color: #f6b734;
}
.right-content-wrap .title-block.no-bg {
  padding: 0;
}
.right-content-wrap .title-block.no-bg .title {
  color: #fff;
}
.right-content-wrap .title-block .title {
  display: block;
  margin: 3px 0 0;
  color: #fff;
}
.right-content-wrap .title-block .title-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 -10px;
}
.right-content-wrap .title-block .left-block {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.right-content-wrap .title-block .left-block .icon {
  display: block;
  margin: 0 10px 0 0;
}
.right-content-wrap .title-block .left-block .icon img {
  display: block;
  object-fit: contain;
  max-height: 64px;
}
.right-content-wrap .title-block .right-block {
  margin: 0 0 10px;
  position: relative;
}
.right-content-wrap .title-block textarea.form-control {
  resize: none;
}
.right-content-wrap .help-popup {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1005;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
}
.right-content-wrap .help-popup.active {
  opacity: 1;
  visibility: visible;
}
.right-content-wrap .help-popup .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s;
}
.right-content-wrap .help-popup .help-popup-inner {
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #2d2c2c;
  padding: 25px 20px;
  max-width: 520px;
  width: 100%;
  position: relative;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  margin: 20px auto;
}
.right-content-wrap .help-popup .help-popup-inner .title-wrap {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
}
.right-content-wrap .help-popup .help-popup-inner .title-wrap h2 {
  margin-right: 10px;
}
.right-content-wrap .help-popup .help-popup-inner .title-wrap .close-btn {
  margin-top: -15px;
  margin-right: -5px;
  border: 1px solid #fff;
  padding: 3px;
  display: block;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-size: 32px;
}
.right-content-wrap .help-popup .help-popup-inner .content p {
  font-size: 18px;
  line-height: 1.8;
  color: #fff;
}
.right-content-wrap .help-popup .help-popup-inner .content .white-btn {
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  padding: 3px 8px 0;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: unset;
  height: 100%;
  margin: 0 3px;
  border: 1px solid #fff;
}
.right-content-wrap .help-popup .help-popup-inner .content .red-btn {
  border-radius: 5px;
  background-color: #c52c2c;
  color: #fff;
  padding: 3px 8px 0;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: unset;
  height: 100%;
  margin: 0 3px;
  border: 1px solid #fff;
}
.right-content-wrap .help-popup .help-popup-inner .content .gray-btn {
  border-radius: 5px;
  background-color: #2d2c2c;
  color: #fff;
  padding: 3px 8px 0;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: unset;
  height: 100%;
  margin: 0 3px;
  border: 1px solid #fff;
}
.right-content-wrap .help-popup .help-popup-inner .content .gray-btn .icon {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  transform: rotate(-90deg);
}
/* nav wrap */
.right-content-wrap .nav-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.nav-wrap .form-group textarea.form-control {
  overflow: hidden;
}
.nav-wrap .form-group textarea.form-control.heading {
  font-size: 24px;
  padding: 10px 10px;
}
.right-content-wrap .custom-dropdown-wrapper.btn {
  width: 100%;
}
.right-content-wrap .custom-dropdown-wrapper.btn > span {
  display: inline-flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
}
.right-content-wrap .nav-wrap .left-side {
  flex: 1;
}
.right-content-wrap .nav-wrap .left-side .left-inner {
  padding-right: 10px;
}
.right-content-wrap .nav-wrap .right-wrap {
  max-width: 340px;
  flex: 0 0 340px;
}
.right-content-wrap .nav-wrap .right-wrap .dropdown {
  flex: 1;
}
.right-content-wrap .right-content-inner {
  padding: 10px 0;
}
/* modal-detail-block */
.modal-detail-block {
  color: #000;
  padding: 25px;
  border: 3px solid #fff;
  margin: 0 0 40px;
  border-radius: 10px;
  position: relative;
}
.modal-detail-block .link-to {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
}
.modal-detail-block .row {
  align-items: center;
}
.modal-detail-block .left-col {
  flex: 0 0 250px;
  max-width: 250px;
  text-align: center;
  padding: 0;
}
.modal-detail-block .left-col .img-block {
  margin: 0 10px 0 0;
}
.modal-detail-block .left-col .content {
  font-size: 22px;
  line-height: 1.13;
  text-transform: uppercase;
  margin-top: 20px;
}
.modal-detail-block .content-col {
  flex: 1;
  padding-left: 20px;
}
.modal-detail-block .content-col .content-dropdown-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.modal-detail-block .content-col .content-dropdown-wrap .content-wrap {
  flex: 1;
  margin-right: 15px;
}
.modal-detail-block .content-col .title {
  margin: 0;
}
.modal-detail-block .content-col .sub-title {
  font-size: 20px;
  line-height: 1.38;
  display: block;
}
.modal-detail-block .content-col p {
  font-size: 20px;
  line-height: 1.38;
  margin: 0;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.modal-detail-block .content-col p strong {
  display: inline-block;
  margin-right: 10px;
}
.modal-detail-block .content-col p span {
  display: inline-block;
}
.modal-detail-block .dropdown-col {
  flex: 0 0 500px;
  max-width: 500px;
}
.modal-detail-block .dropdown-col .btn-dropdown-wrap {
  display: flex;
  align-items: center;
}
.modal-detail-block .dropdown-col .btn-dropdown-wrap > .btn {
  white-space: nowrap;
  width: 1;
}
.modal-detail-block .custom-dropdown {
  position: relative;
  z-index: 2;
  margin-left: 10px !important;
}
.right-content-wrap .right-content-inner .no-record-found {
  font-size: 22px;
  line-height: 1.25;
  color: #ffff;
}
.dashboard-start-modal .right-content-wrap {
  padding: 0;
  height: auto;
  max-width: 100%;
  flex: 100%;
}
.dashboard-start-modal .right-content-wrap::after {
  display: none;
}
.dashboard-start-modal .right-content-wrap .right-content-inner {
  padding: 0;
}
.dashboard-start-modal .right-content-wrap > .title-block {
  display: none;
}
/* two col section featured block */
.two-col-section .row {
  margin: 0 -10px -20px;
}
.two-col-section .col {
  flex: 0 0 20%;
  max-width: 20%;
  margin: 0 0 20px;
  padding: 0 10px;
}
.two-col-section .inner-col {
  background-color: #fff;
  color: #000;
  padding: 25px 15px;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.two-col-section .inner-col .img-block {
  margin: 0 0 20px;
  text-align: center;
}
.two-col-section .inner-col .img-block img {
  height: 160px;
  object-fit: contain;
}
.two-col-section .inner-col .content {
  font-size: 22px;
  line-height: 1.13;
  color: #000;
  display: block;
  text-align: center;
  margin: 0 0 20px;
}
.two-col-section .inner-col p {
  font-size: 14px;
  line-height: 1.38;
  margin: 0 0 20px;
}
.featured-model .col:nth-child(1) .inner-col {
  background-color: #51efcc;
}
.featured-model .col:nth-child(2) .inner-col {
  background-color: #59d9ff;
}
.featured-model .col:nth-child(3) .inner-col {
  background-color: #c951f0;
}
.featured-model .col:nth-child(4) .inner-col {
  background-color: #33b1fb;
}
.featured-model .col:nth-child(5) .inner-col {
  background-color: #f6b734;
}
.two-col-section .inner-col .btn {
  max-width: 160px;
  width: 100%;
}
/* custom tabs */
.custom-tabs {
  display: flex;
  margin: 0 0 50px;
}
.custom-tabs .nav-item {
  max-width: none;
  flex: 1;
  margin-bottom: 0;
}
.custom-tabs .nav-item button.active {
  background-color: #fff;
  color: #000;
}
.custom-tabs .nav-item button {
  width: 100%;
  padding: 8px;
  background-color: transparent;
  color: #fff;
  height: 100%;
  font-size: 18px;
  line-height: 1.25;
  white-space: nowrap;
}
.custom-tabs-body.update-pass .tabs-body-inner {
  max-width: 500px;
  margin: 0 auto;
}
.custom-tabs-body .top-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.custom-tabs-body .top-block .form-group {
  max-width: 49%;
  flex: 0 0 49%;
}
.custom-tabs-body.update-pass .top-block .form-group {
  max-width: 100%;
  flex: 0 0 100%;
}
.custom-tabs-body .form-group.address {
  max-width: 100%;
  flex: 0 0 100%;
}
.custom-tabs-body .outer-content-wrap {
  height: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 25px 45px 25px 25px;
  position: relative;
}
.custom-tabs-body .settings-title-block {
  margin: 0 0 10px;
}
.custom-tabs-body .settings-title-block .title {
  font-size: 26px;
  line-height: 1.3;
  color: #fff;
  display: block;
  margin: 0 0 5px;
}
.custom-tabs-body .settings-title-block .subtitle {
  font-size: 26px;
  line-height: 1.3;
  color: #fff;
  display: block;
}
.custom-tabs-body .settings-title-block .subtitle.small {
  font-size: 16px;
}
.custom-tabs-body .settings-title-block .card-detail {
  background-color: #fff;
  border-radius: 0;
  padding: 10px 15px;
  font-size: 16px;
  color: #808285;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 330px;
  width: 100%;
}
.custom-tabs-body .settings-title-block .btn {
  width: 100%;
}
.custom-tabs-body .outer-content-wrap p {
  font-size: 20px;
  line-height: 1.3;
  color: #fff;
  display: block;
}
.custom-tabs-body .outer-btn-wrap {
  display: flex;
  flex-wrap: wrap;
}
.custom-tabs-body .outer-btn-wrap .btn:not(:last-child) {
  margin-right: 10px;
}
.custom-tabs-body .outer-content-wrap .edit-btn {
  position: absolute;
  background-color: transparent;
  border: none;
  outline: 0;
  padding: 2px;
  border-radius: 3px;
  box-shadow: none;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  color: #fff;
  z-index: 1;
}
/* modals */
.modal .form-group p {
  font-size: 18px;
  line-height: 1.2;
  color: #000;
}
.modal-title {
  font-size: 24px;
  line-height: 1.2;
  color: #000;
}
.modal .card-detail-title {
  display: block;
  font-size: 20px;
  line-height: 1.2;
  color: #000;
  margin: 0 0 10px;
}
.right-content-wrap .data-block-outer {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.grid-block .custom-col {
  flex: 1;
}
.right-content-wrap .data-block-outer .info-wrapper {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #fff;
}
.data-block-outer .row:first-child .right-col button {
  padding: 10px 10px 7px 10px;
}
.data-block-outer .right-col {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}
.data-block-outer .right-col h3 {
  margin-bottom: 0 !important;
}
.right-content-wrap .data-block {
  background-color: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.right-content-inner .border-right li {
  background-color: #6b6969;
  width: fit-content;
  padding: 4px 15px 4px 0;
  border-radius: 10px;
  color: white !important;
  margin: 5px 0;
}
.right-content-inner .border-right li .text-value {
  padding-right: 12px;
}
.right-content-inner .border-right li button {
  border: 0;
  top: 1.5px;
  border-radius: 50%;
  padding: 4px 6px 6px 6px;
  color: white;
  right: -5px;
}
.right-col .content-block .list-wrapper div {
  padding-right: 20px;
  word-break: break-all;
}
.grid-block > .upper-block {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #231f20;
  flex: 1;
}
.grid-block > .upper-block .column {
  flex: 0 0 20%;
  max-width: 20%;
}
.grid-block .column:not(:last-child) {
  border-right: 1px solid #231f20;
}
.grid-block > .upper-block .column .inner-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.grid-block > .lower-block {
  height: 40%;
  display: flex;
  flex-wrap: wrap;
}
.grid-block > .lower-block .column {
  flex: 0 0 50%;
  max-width: 50%;
}
.grid-block .content-block {
  padding: 20px 10px 5px;
  position: relative;
  height: 100%;
}
.grid-block .delete-row {
  font-size: 0px;
  display: inline-block;
  padding: 0;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: -3px;
  z-index: 1;
  background-color: #000;
  color: #fff;
  display: inline-flex;
  align-items: center;
  border: none;
  justify-content: center;
  background-position: center;
  background-size: 21px;
  background-image: url("../assets/images/close-rounded.svg");
}
.grid-block .list-wrapper {
  position: static;
}
.grid-block .content-block .title {
  font-size: 18px;
  line-height: 1.38;
  color: #000;
  margin: 0;
  width: 100%;
  display: block;
}
.grid-block.atw-table-block {
  display: flex;
  flex-direction: column;
  min-height: 400px;
}
.grid-block.atw-table-block .lower .content-block {
  padding-top: 5px;
}
.grid-block .content-block .content textarea {
  max-width: 100%;
  width: 100%;
  border: none;
  resize: none;
  background: #e6e7e8;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 1.38;
  color: #000;
  border-radius: 10px;
}
.grid-block .content-block .content textarea::placeholder {
  font-style: italic;
  color: #000;
}
.grid-block .content-block .content ol {
  list-style-position: outside;
  list-style-type: decimal;
  padding-left: 18px;
  max-width: 100%;
  width: 100%;
}
.grid-block .content-block .content ol > textarea {
  margin-right: 18px;
  width: calc(100% - 18px);
}
.grid-block .content-block .content ol li {
  font-size: 18px;
  line-height: 1.38;
  color: #000;
  width: 100%;
  position: relative;
  margin: 0 0 5px;
}

.grid-block.atw-table-block
  .content-block
  .content
  ol
  .list-wrapper
  .text-value {
  color: #000;
}
.grid-block .content-block .content ol .custom-input {
  max-width: 100%;
}
.right-content-wrap .data-block .row .left-col .content {
  background-color: #6b6969;
  justify-content: center;
}
.right-content-wrap .data-block .row .left-col .content ol {
  color: white;
}
.grid-block .upper {
  height: 100%;
}
.grid-block .lower {
  height: 100%;
}
.grid-block .upper.border-bottom {
  border-bottom: 1px solid #231f20 !important;
}
.grid-block > .lower-block .content-block .content {
  min-height: 100px;
}
/* two col grid */
.grid-block.two-col-grid .row {
  margin: 0;
}
.grid-block.two-col-grid .left-col {
  border-right: 1px solid #231f20;
  padding: 0 10px;
}
.grid-block.two-col-grid .right-col {
  padding: 0 10px;
}
.grid-block.two-col-grid .title-row .title {
  font-size: 24px;
  line-height: 1;
  color: #000;
  text-transform: uppercase;
  width: auto;
  display: inline-block;
}
.grid-block.two-col-grid .title-row .btn {
  display: inline-flex;
  margin-left: 20px;
}
.grid-block.two-col-grid .title-row .content-block {
  padding: 20px 10px 20px;
  display: flex;
  align-items: center;
  min-height: auto;
}
.grid-block.two-col-grid .content-block {
  padding: 0 10px 10px;
  min-height: 96px;
}
.grid-block.two-col-grid .content-block .content {
  background-color: transparent !important;
}
.grid-block.two-col-grid .right-col .content-block {
  width: 100%;
}
.grid-block.two-col-grid.tech-stack .right-col .content-block {
  width: calc(100% - 20px);
}
.grid-block.two-col-grid.days-plan .right-col .content-block {
  width: 75%;
  margin: 0 auto;
}
.grid-block.two-col-grid.days-plan .left-col {
  max-width: 25%;
  flex: 0 0 25%;
}
.grid-block.two-col-grid.days-plan .right-col {
  flex: 0 0 75%;
  max-width: 75%;
}
.grid-block.two-col-grid.days-plan .left-col .days {
  font-size: 25px;
  line-height: 1.6;
  color: #000;
}
.grid-block.two-col-grid.days-plan .right-col ol {
  padding-left: 22px;
}
.grid-block.two-col-grid.days-plan .right-col .delete-row {
  right: 0;
  top: 2px;
}
.grid-block.two-col-grid.days-plan .form-check .form-check-input {
  margin-left: -60px;
  margin-top: 0;
}
/* tesla value */
.grid-block.two-col-grid.tesla-value .left-col {
  max-width: 45%;
  flex: 0 0 45%;
}
.grid-block.two-col-grid.tesla-value .right-col {
  flex: 0 0 55%;
  max-width: 55%;
}
/* tech stack */
.grid-block.two-col-grid.tech-stack .left-col {
  max-width: 25%;
  flex: 0 0 25%;
}
.grid-block.two-col-grid.tech-stack .right-col {
  flex: 0 0 75%;
  max-width: 75%;
  position: relative;
}
.grid-block.two-col-grid.tech-stack .right-col .delete-row {
  right: 15px;
}
.text-strick {
  text-decoration: line-through;
}
.error-message {
  color: red;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 16px;
  line-height: 1;
}
.close-button {
  background: #000;
  color: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-right: 18px;
}
.list-wrapper .text-value {
  word-break: break-all;
}
.list-wrapper > div {
  color: #000;
  padding-right: 5px;
}
.more-option .dropdown-toggle img {
  max-width: 20px;
}
.more-option .dropdown-toggle {
  background: #fff !important;
  min-width: 50px;
  border-color: #fff !important;
  box-shadow: none !important;
}
.more-option .dropdown-toggle:after {
  display: none;
}
.custom-dropdown-button {
  min-width: 90px;
}
.has-sub-menu {
  position: relative;
}
.has-sub-menu .sub-menu li a {
  padding-left: 50px !important;
}
.err-msg {
  color: #ff0000;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 16px;
}
/* start pagination */
.my-pagination {
  margin: 10px 0 10px 0;
}
.my-pagination ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-pagination ul li:first-child a {
  border-radius: 10px 0 0 10px;
}
.my-pagination ul li:last-child a {
  border-radius: 0 10px 10px 0;
}
.my-pagination ul li a:hover,
.my-pagination ul li.selected a,
.my-pagination ul li.selected a:hover {
  color: #000 !important;
  background-color: #fff;
}
.my-pagination ul li a {
  color: #fff !important;
  padding: 10px 20px;
  background-color: #000;
  transition: all 0.3s;
  font-size: 16px;
  line-height: 1;
  display: block;
  border: 1px solid #444;
}
/* End pagination */
/* start admin css */
.admin-right-sec {
  padding: 20px 10px;
}
.admin-right-sec table {
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px;
}
.admin-right-sec td button {
  border: 0;
  position: relative;
  background-color: unset;
  border-bottom: 1px solid white;
  color: white;
}
.user-info-sec ul.user-info-inner {
  margin-bottom: 30px;
}
.user-info-sec ul.user-info-inner li {
  max-width: 20%;
  flex: 0 0 20%;
  padding: 8px;
  color: #fff;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s;
  text-align: center;
  border-radius: 5px 5px 0 0;
  margin-bottom: -1px;
}
.user-info-sec ul.user-info-inner li:hover {
  border: 1px solid #fff;
}
.user-info-sec ul.user-info-inner li.active-list {
  border-color: #dee2e6 #dee2e6 #fff;
  background: #fff;
  color: #000;
  border-bottom: 0;
}
.admin-right-sec table td {
  position: relative;
}
.admin-right-sec table td input.form-check-input {
  left: 50%;
  transform: translateX(-50%);
}
.admin-seach {
  position: relative;
  max-width: 230px;
  width: 100%;
  border: 0;
  margin-top: 20px;
  margin-bottom: 30px;
}
.admin-seach input.form-control {
  border: 0;
  border-bottom: 1px solid #00000094;
  border-radius: 0;
  padding-left: 0;
  box-shadow: rgb(33 35 38 / 10%) 0px 10px 10px -10px;
  background-color: transparent;
  border-bottom: 1px solid #fff;
  color: #fff;
}
.admin-seach input.form-control::placeholder {
  color: #000;
}
.admin-seach img {
  position: absolute;
  width: 14px;
  top: 11px;
  left: 0;
  display: none;
}
/* end admin css */
.strip-img {
  max-width: 500px;
  width: 100%;
  margin: auto;
}
.strip-img img {
  width: 100%;
}
.share-modal.modal-dialog {
  max-width: 600px;
}
.share-modal .modal-footer .btn {
  flex: 1;
}
.share-modal .modal-dialog .form-group label {
  color: #000;
}
.popup-data ul,
.popup-data .popup-data-items {
  margin: 0 -10px;
}
.popup-data ul li:first-child,
.popup-data .popup-data-items p:first-child {
  width: 180px;
}
.popup-data ul li:nth-child(2),
.popup-data .popup-data-items p:nth-child(2) {
  width: 300px;
}
.popup-data ul li:last-child,
.popup-data .popup-data-items p:last-child {
  width: 70px;
}
.popup-data .popup-data-items span {
  width: 70px;
  display: block;
  text-align: center;
  padding: 8px 8px;
}
.popup-data .popup-data-items span .delete-row {
  font-size: 0;
  display: inline-block;
  padding: 0;
  height: 22px;
  width: 22px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  z-index: 1;
  background-color: #000;
  color: #fff;
  display: inline-flex;
  align-items: center;
  border: none;
  justify-content: center;
  background-position: center;
  background-size: 19px;
  background-image: url("../assets/images/close-rounded.svg");
}
.popup-data ul li,
.popup-data-items p {
  font-size: 18px !important;
  line-height: 1 !important;
  height: 38px;
  padding: 10px 10px;
  text-align: left;
}
.modal-body .modal-footer {
  margin: 0 -1rem -1rem -1rem;
}
.modal-body .pay-info-inner .modal-footer {
  justify-content: center;
}
.change-card-modal .form-group p.err-msg {
  font-size: 16px;
  color: #ff0000;
  line-height: 1;
  margin-top: 3px;
}
.full-page-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.full-page-wrapper .page-loader {
  z-index: 11;
}
.full-page-wrapper:after {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0 0 0 / 76%);
}
.not-allowed {
  cursor: not-allowed;
}
.pointer {
  cursor: pointer;
}
.admin-table-wrapper {
  overflow-x: auto;
}
.admin-table-wrapper table {
  min-width: 1200px;
}
.admin-table-wrapper.featured-list-table table {
  min-width: 700px;
}
.admin-table.table thead th,
.admin-table.table tbody td,
.admin-table th {
  color: #fff;
  background-color: #000;
}
.admin-table-wrapper .text-center {
  text-align: center;
}
.admin-table-wrapper .text-center .form-check {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-content-wrap.admin-right-wrap {
  max-width: calc(100% - 320px);
  flex: 0 0 calc(100% - 320px);
  margin-left: 320px;
}
.right-content-wrap.admin-right-wrap::after {
  display: none;
}
.right-content-wrap.admin-right-wrap .admin-seach {
  margin-left: auto !important;
}
.four-col-block-admin .col {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  margin: 0 0 30px;
}
.four-col-block-admin .inner-col {
  padding: 15px 15px 25px;
  background-color: #efefef;
  text-align: center;
  border-radius: 10px;
  height: 100%;
  display: flex;
}
.four-col-block-admin .img-block {
  height: 100px;
  margin: 0 20px 0 0;
}
.four-col-block-admin .content {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: center;
}
.four-col-block-admin .img-block img {
  height: 100%;
  display: block;
  object-fit: contain;
  margin: 0 auto;
}
.four-col-block-admin .title {
  display: block;
  font-size: 20px;
  line-height: 1;
  color: #111;
  text-align: left;
}
.four-col-block-admin .number {
  font-size: 34px;
  line-height: 1;
  display: block;
  color: #111;
  margin: 0 0 12px;
}
.user-modal .four-col-block-admin .col {
  flex: 0 0 25%;
  max-width: 25%;
}
.user-modal .modal-dialog {
  max-width: 90%;
}
.user-modal .data-content-wrap {
  padding: 0 0 20px;
}
.user-modal .data-content-wrap p {
  font-size: 18px;
  line-height: 1;
  color: #000;
  margin: 0 0 10px;
}
.user-modal .data-content-wrap p span {
  margin-left: 10px;
}
.share-modal .form-group textarea.form-control,
.user-modal .form-group textarea.form-control {
  min-height: 130px;
}
.swal2-title {
  color: #000;
  font-size: 24px !important;
  line-height: 32px;
}
.swal2-icon{
  font-size: 10px !important;
}
.swal2-actions button {
  font-size: 18px;
  line-height: 1.38;
}
.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #2d2c2c;
  padding: 10px 30px;
  border-bottom: 1px solid #333;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  height: 72px;
}
.mobile-header .left-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mobile-header .hamburger {
  margin: 0 15px 0 0;
  cursor: pointer;
}
.mobile-header .logout-link {
  color: #fff;
  font-size: 24px;
}
.two-btn-wrap .btn:not(:last-child) {
  margin: 0 15px 0 0;
}
.custom-datepicker {
  max-width: 100%;
}
.milestone-dialog textarea {
  width: 100%;
  margin-bottom: 20px;
  min-height: 100px;
  resize: none;
}
.milestone-dialog .btn-wrapper {
  padding-bottom: 20px;
  border-bottom: 1px solid #ced4da;
  margin-bottom: 20px;
}
.close-btn {
  border-radius: 6px;
  padding: 2px;
  width: 20px;
  height: 20px;
  line-height: 14px;
  font-size: 15px;
  background: #000;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 0;
  background-size: 19px;
  background-position: center;
  background-image: url("../assets/images/close-rounded.svg");
  background-repeat: no-repeat;
}
.milestone-dialog .checklist-wrapper .list-wrap {
  display: flex;
  align-items: flex-start;
  padding: 5px 25px 0 0;
  position: relative;
}
.milestone-dialog .checklist-wrapper span {
  word-break: break-all;
  line-height: 1.4;
}
.milestone-dialog .checklist-wrapper input {
  margin-right: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.milestone-dialog .checklist-wrapper .close-btn {
  margin-left: 10px;
  position: absolute;
  right: 0;
}
.grid-block.vision-board {
  padding: 20px;
  overflow-x: auto;
}
.visionboard-table {
  border: 1px solid #000;
}
.visionboard-table .form-control {
  min-width: 230px;
}
.visionboard-table td {
  max-width: 250px;
}
.visionboard-table .milestone-td > div {
  display: list-item;
  list-style-type: decimal;
  list-style-position: outside;
  margin-left: 15px;
}
.visionboard-table th {
  white-space: nowrap;
}
.visionboard-table td:not(:last-child),
.visionboard-table th:not(:last-child) {
  border-right: 1px solid #000;
}
.visionboard-table td,
.visionboard-table th {
  width: 250px;
  padding: 10px;
  word-break: break-all;
}
.visionboard-table thead th {
  border-top: none;
  border-bottom: 1px solid #000;
}
.visionboard-table .list-wrapper {
  padding-right: 0;
}
.visionboard-table .list-wrapper textarea.form-control {
  height: 46px;
}
/* sidebar */
.sidebar {
  position: fixed;
  z-index: -1;
  transform: translateX(-100%);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}
.sidebar.is-open {
  z-index: 1003;
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.sidebar .overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
  opacity: 0;
  visibility: inherit;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s;
}
.sidebar.is-open .overlay {
  z-index: 0;
  opacity: 1;
  visibility: visible;
}
.sidebar .sidebar-wrap {
  height: 100vh;
  overflow-y: auto;
}
.sidebar .bg-img {
  top: 0;
}
.sidebar .sidebar-wrap .logo-wrap i img {
  max-width: 230px;
}
.logo-wrap {
  max-width: 230px;
}
.hamburger img {
  width: 50px;
}
.CardBrandIcon.is-loaded img,
.CardBrandIcon.is-loaded svg {
  height: 40px;
  width: 40px;
}
.mail-dialog .form-group textarea.form-control {
  background-color: transparent !important;
}
th.sort-by {
  padding-right: 18px;
  position: relative;
}
.sort-by:before,
.sort-by:after {
  border: 4px solid transparent;
  content: "";
  display: block;
  height: 0;
  right: 5px;
  top: 50%;
  position: absolute;
  width: 0;
}
.sort-by:before {
  border-bottom-color: #666;
  margin-top: -9px;
}
.sort-by:after {
  border-top-color: #666;
  margin-top: 1px;
}
.sort-by.asc:after {
  display: none;
}
.sort-by.desc:before {
  display: none;
}
.info-wrapper {
  position: relative;
  display: inline-block;
}
.info-wrapper .tooltiptext {
  visibility: hidden;
  min-width: 120px;
  width: 160px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 0;
  font-size: 12px;
  line-height: 18px;
}
.info-wrapper:hover .tooltiptext {
  visibility: visible;
  background-color: #000;
}
.iframe-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.iframe-inner {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 20px;
}
.text {
  color: #000000;
  font-size: 18px;
  margin: 0 0 5px;
}
.textArea-Common-background {
  background-color: #e6e7e8 !important;
  border-radius: 10px !important;
  padding: 5px 0 0 !important;
  margin-right: 18px;
}

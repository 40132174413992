@media (max-width: 1439px) {
  .sidebar {
    max-width: 380px;
    flex: 0 0 380px;
  }
  .sidebar .sidebar-wrap .menu-list li a {
    padding: 15px 30px;
  }
  .sidebar .sidebar-wrap .logo-wrap {
    padding: 30px 20px;
  }
  .admin-sidebar.sidebar {
    flex: 0 0 250px;
    max-width: 250px;
  }
  .right-content-wrap.admin-right-wrap {
    margin-left: 250px;
    flex: 0 0 calc(100% - 250px);
    max-width: calc(100% - 250px);
  }
}
@media (max-width: 1280px) {
  .user-modal .four-col-block-admin .col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .right-content-wrap .data-block {
    padding: 35px 5px;
    box-shadow: none;
  }
  .grid-block > .lower-block .content-block .content {
    min-height: 70px;
  }
  .swal2-title {
    font-size: 22px;
    line-height: 30px;
  }
  .swal2-icon.swal2-error.swal2-icon-show {
    font-size: 10px;
  }
  .four-col-block-admin .inner-col {
    flex-direction: column;
    align-items: center;
  }
  .four-col-block-admin .img-block {
    margin: 0 0 20px;
  }
  .four-col-block-admin .content {
    align-items: center;
  }
  .four-col-block-admin .title {
    text-align: center;
    font-size: 18px;
  }
  .four-col-block-admin .number {
    font-size: 30px;
  }
  .two-col-section .featured-model {
    justify-content: center;
  }
  .two-col-section .col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .modal-detail-block .dropdown-col .btn-dropdown-wrap {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .modal-detail-block .dropdown-col {
    max-width: 250px;
    flex: 0 0 250px;
  }
  .modal-detail-block .dropdown-col .btn-dropdown-wrap > * {
    width: 100%;
    margin: 0 0 10px !important;
  }
  .modal-detail-block .dropdown-col .btn-dropdown-wrap > .custom-dropdown {
    margin-left: 0 !important;
  }
}
@media (max-width: 1100px) {
  h1 {
    font-size: 32px;
  }
  .right-content-wrap .title-block .sub-title {
    font-size: 22px;
  }
  .sidebar {
    max-width: 320px;
    flex: 0 0 320px;
  }
  .sidebar .sidebar-wrap .logo-wrap {
    padding: 10px 5px;
  }
  .sidebar .sidebar-wrap .menu-list li a {
    font-size: 22px;
    margin: 0;
    padding: 15px 20px;
  }
  .two-col-section .inner-col {
    padding: 20px 15px;
  }
  .two-col-section .inner-col .content {
    font-size: 20px;
  }
  .modal-detail-block {
    padding: 20px;
    margin: 0 0 30px;
  }
  .modal-detail-block .left-col .content {
    font-size: 20px;
  }
  .modal-detail-block .left-col {
    max-width: 200px;
    flex: 0 0 200px;
  }
  .my-pagination {
    margin: 10px 0 20px 0;
  }
  .custom-tab-outer {
    position: relative;
  }
  .custom-tab-outer .custom-tabs {
    padding-bottom: 15px;
  }
  .custom-tab-outer::after {
    content: "";
    height: 1px;
    background-color: #fff;
    left: 0;
    right: 0;
    position: absolute;
    top: 40px;
  }
  .custom-tab-outer .custom-tabs {
    border: none;
  }
  .custom-tabs {
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .custom-tabs .nav-item {
    max-width: fit-content;
    flex: 1;
  }
  .custom-tabs .nav-item button {
    white-space: nowrap;
    padding: 10px 15px;
  }
}
@media (max-width: 1024px) {
  .btn.big-btn {
    font-size: 20px;
  }
  .right-content-wrap .title-block .sub-title {
    font-size: 20px;
  }
  .login-page .prelogin-header {
    padding: 10px 0;
  }
  .login-page .prelogin-header .logo-wrap {
    margin: 0;
    max-width: 230px;
  }
  .login-page .img-form-section {
    padding: 50px 0;
  }
  .login-page .right-content-wrap {
    padding: 30px 30px;
  }
  .login-page .right-content-wrap .title-block {
    padding: 0 0 20px;
    max-width: none;
  }
  .login-page .right-content-wrap .btn-wrap {
    margin: 30px 0 10px;
  }
  .login-page .right-content-wrap .link-wrap {
    margin: 0 0 20px;
  }
  .login-page .left-bg-wrap .inner-wrap {
    padding: 30px;
  }
  .login-page .left-bg-wrap .frame-block {
    margin: 0 0 40px;
  }
  .logo-footer {
    padding: 35px 0;
  }
  .login-page .payment-text {
    font-size: 18px;
    margin: 30px 0 20px;
  }
  .login-page .text-link-wrap.text-center .content,
  .login-page .text-link-wrap.text-center .primary-link {
    font-size: 16px;
  }
  .login-page .img-form-section .row-wrap,
  .login-page .img-form-section .row-wrap.small-width {
    max-width: 500px;
    margin: 0 auto;
    min-height: auto;
  }
  .login-page .left-bg-wrap {
    display: none;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .login-page .right-content-wrap {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 30px 20px;
  }
  .login-page .right-content-wrap .logo-wrap-bottom {
    max-width: 320px;
    margin: 20px auto 0;
  }
  .right-content-wrap .nav-wrap .left-side .left-inner {
    padding: 0;
  }

  .right-content-wrap .nav-wrap .right-wrap {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .right-content-wrap .nav-wrap .right-wrap .right-inner {
    width: 100%;
    display: flex;
  }
  .right-content-wrap .nav-wrap .right-wrap .right-inner > * {
    flex: 1;
  }
  .right-content-wrap .nav-wrap .right-wrap .right-inner > *:not(:last-child) {
    margin-right: 10px;
  }
  .right-content-wrap .data-block {
    overflow-x: auto;
  }
  .grid-block .content-block .title {
    font-size: 16px;
  }
  .grid-block .content-block .content textarea {
    font-size: 14px;
  }
  .grid-block .content-block .content ol li {
    font-size: 14px;
  }
  .grid-block.two-col-grid .title-row .title {
    font-size: 22px;
  }
  .grid-block.two-col-grid.tesla-value .title-row .content-block {
    padding: 0 10px 20px !important;
  }
  .grid-block.two-col-grid.tesla-value h4.title {
    margin: 0 0 15px;
  }
  .grid-block.two-col-grid.days-plan .left-col .days {
    font-size: 23px;
  }
  .grid-block.two-col-grid.days-plan .content-block {
    padding: 0 0 30px;
  }
  .grid-block.two-col-grid.days-plan .right-col .content-block{
    width: 90%;
  }
    .grid-block.two-col-grid.days-plan .right-col ol li {
    font-size: 20px;
  }
  .right-content-wrap .data-block .grid-block.atw-table-block{
  	min-width: 1024px;
  }
   

}
@media (max-width: 991px) {
  .btn {
    min-width: 110px;
  }
  .logo-footer {
    padding: 25px 0;
  }
  .login-page .left-bg-wrap .canvas-mode {
    font-size: 28px;
  }
  .login-page .left-bg-wrap .logo-wrap {
    max-width: 220px;
  }
  .sidebar {
    position: fixed;
    z-index: -1;
    transform: translateX(-100%);
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar.is-open {
    z-index: 1003;
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
  .sidebar .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
    opacity: 0;
    visibility: inherit;
    width: 100vw;
    height: 100vh;
    transition: all 0.3s;
  }
  .sidebar.is-open .overlay {
    z-index: 0;
    opacity: 1;
    visibility: visible;
  }
  .sidebar .sidebar-wrap {
    height: 100vh;
    overflow-y: auto;
  }
  .sidebar .bg-img {
    top: 0;
  }
  .sidebar .sidebar-wrap .logo-wrap i img {
    max-width: 230px;
  }
  .right-content-wrap {
    padding: 95px 15px 30px;
  }
  .mobile-header .logo-wrap {
    max-width: 200px;
  }
  .two-col-section .featured-model {
    justify-content: center;
  }
  .right-content-wrap .data-block-outer .info {
    top: 5px;
    right: 5px;
  }
  
  .right-content-wrap .data-block .grid-block {
    width: 910px;
  }
  .grid-block.two-col-grid.tech-stack .right-col .content-block {
    width: 95%;
  }
  .modal-detail-block .content-col .content-dropdown-wrap .content-wrap {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 15px;
  }
  .modal-detail-block .dropdown-col {
    max-width: 500px;
    flex: 1;
  }
  .modal-detail-block .dropdown-col .btn-dropdown-wrap > * {
    width: 190px;
  }
  .custom-tabs-body .settings-title-block .title {
    font-size: 22px;
  }
  .custom-tabs-body .settings-title-block .subtitle {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .custom-tabs-body .settings-title-block .subtitle + .subtitle {
    margin-top: 20px;
    font-weight: 600;
  }
  .custom-tabs-body .outer-content-wrap p {
    font-size: 18px;
  }
  .grid-block.two-col-grid.days-plan .right-col .content-block{
    width: 100%;
  }
  .grid-block.two-col-grid.days-plan .form-check .form-check-input{margin-left: -63px;}
	.grid-block.two-col-grid.days-plan .right-col ol{padding-left: 40px;}
}
@media (max-width: 767px) {
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h4 {
    font-size: 16px;
  }
  .right-content-wrap .title-block .sub-title {
    font-size: 18px;
  }
  .primary-link {
    font-size: 14px;
  }
  .btn {
    min-width: 90px;
  }
  .login-page .text-link-wrap .content {
    font-size: 14px;
  }
  .login-page .img-form-section {
    padding: 35px 0;
  }
  .login-page .payment-text {
    font-size: 16px;
    margin: 20px 0;
  }
  .login-page .text-link-wrap.text-center .content,
  .login-page .text-link-wrap.text-center .primary-link {
    font-size: 16px;
  }
  .login-page .right-content-wrap .btn-wrap {
    margin: 20px 0 10px;
  }
  .error-message {
    font-size: 14px;
  }
  .two-col-section .col {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .two-col-section .inner-col {
    padding: 15px;
  }
  .modal-detail-block .left-col .content {
    font-size: 18px;
  }
  .two-col-section .inner-col .content {
    font-size: 18px;
  }
  .modal-detail-block .content-col .sub-title {
    font-size: 18px;
  }
  .modal-detail-block .content-col p {
    font-size: 18px;
  }
  .two-btn-wrap {
    text-align: center;
  }
  .two-btn-wrap .btn {
    margin: 0 10px 15px !important;
  }
  .custom-tabs .nav-item button {
    font-size: 16px;
  }
  .right-content-wrap .nav-wrap .right-wrap .right-inner {
    flex-direction: column;
  }
  .right-content-wrap .nav-wrap .right-wrap .right-inner > *:not(:last-child) {
    margin-right: 0;
  }
  .right-content-wrap .nav-wrap .right-wrap .right-inner .mr-3 {
    margin-right: 10px !important;
  }
  .right-content-wrap .title-block .left-block .icon img {
    max-height: 64px;
  }
  .right-content-wrap .title-block .title-inner {
    flex-wrap: wrap;
  }
  .right-content-wrap .title-block .right-block {
    margin-left: auto;
  }
  .mobile-header {
    padding: 10px 15px;
  }
  .custom-tabs-body .outer-content-wrap {
    padding: 20px 40px 20px 15px;
  }
  .right-content-wrap .help-popup .help-popup-inner .content p {
    font-size: 18px;
  }
  .modal-detail-block .content-col {
    padding: 0 15px;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .modal-detail-block .content-col p,
  .modal-detail-block .content-col .sub-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
  }
  .modal-detail-block .content-col p:last-child {
    border: none;
    margin-bottom: 20px;
  }
  .modal-detail-block .left-col {
    margin: 0 0 20px;
    padding: 0 15px;
    max-width: 250px;
    flex: 1;
    margin: 0 auto;
  }
  .modal-detail-block .dropdown-col .btn-dropdown-wrap > .btn {
    white-space: nowrap;
    margin-right: 10px !important;
    width: auto;
  }
  .modal-detail-block .dropdown-col {
    max-width: 100%;
  }
  .modal-detail-block .dropdown-col .btn-dropdown-wrap {
    flex-direction: row;
  }
  .swal2-title {
    font-size: 18px;
    line-height: 25px;
  }
  .swal2-icon.swal2-error.swal2-icon-show {
    font-size: 8px;
  }
  .grid-block.two-col-grid .title-row .title {
    font-size: 20px;
  }
  .data-block-outer .row:first-child .right-col button {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .share-modal .modal-footer .btn {
    flex: auto;
  }
  .login-page .right-content-wrap .btn-wrap {
    margin: 20px 0 0;
    flex-direction: column;
  }
  .login-page .right-content-wrap .btn-wrap .btn {
    margin: 0 auto 20px !important;
    width: fit-content;
  }
  .hamburger img {
    width: 40px;
  }
  .my-pagination ul li a {
    font-size: 14px;
    padding: 7px 11px;
  }
  .right-content-wrap .right-content-inner .no-record-found {
    font-size: 18px;
  }
  .custom-tabs-body .top-block .form-group {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .grid-block.two-col-grid.days-plan .right-col ol li {
    font-size: 18px;
  }
  .grid-block.two-col-grid.days-plan .left-col .days {
    font-size: 20px;
  }
  .nav-wrap .form-group textarea.form-control.heading {
    font-size: 20px;
    padding: 11px 8px;
  }
  .form-group .form-control,
  .form-group .form-select {
    font-size: 16px;
    padding: 8px 8px 5px;
  }
  .form-group textarea.form-control {
    padding: 13px 8px;
  }
  .mobile-header .right-header {
    display: none;
  }
  .custom-tabs-body .settings-title-block .card-detail {
    font-size: 14px;
    padding: 10px;
  }
  .right-content-wrap .help-popup .help-popup-inner {
    padding: 20px 15px;
  }
  .right-content-wrap .help-popup .help-popup-inner .title-wrap .close-btn {
    min-width: 30px;
    width: 30px;
    height: 30px;
    background-size: 27px;
    margin-top: -10px;
  }
  .right-content-wrap .help-popup .help-popup-inner .content .white-btn,
  .right-content-wrap .help-popup .help-popup-inner .content .gray-btn,
  .right-content-wrap .help-popup .help-popup-inner .content .red-btn {
    height: 21px;
    line-height: 21px;
    margin-bottom: 3px;
  }
  .swal2-title{
    font-size: 20px !important;
  }
  .swal2-icon{
    font-size: 8px !important;
  }
}
@media (max-width: 480px) {
  .two-col-section .col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .modal-detail-block .content-col p {
    font-size: 16px;
  }
  .custom-tabs-body .outer-btn-wrap .btn:not(:last-child) {
    margin-right: 0;
  }
  .custom-tabs-body .outer-btn-wrap .btn {
    margin: 0 0 10px;
    width: 100%;
  }
}
@media (max-width: 420px) {
  .login-page .right-content-wrap {
    padding: 20px 15px;
  }
  .login-page .text-link-wrap.text-center .content .grey-text {
    padding: 0 8px;
  }
}
@media (max-width: 375px) {
  .my-pagination ul li a {
    font-size: 12px;
    padding: 6px 9px;
  }
  .sidebar {
    flex: 0 0 270px;
    max-width: 270px;
  }
  .sidebar .sidebar-wrap .menu-list li a {
    font-size: 18px;
  }
  .modal-detail-block .dropdown-col .btn-dropdown-wrap > * {
    width: 100%;
  }
  .modal-detail-block .dropdown-col .btn-dropdown-wrap > .btn {
    margin: 0 0 10px !important;
  }
}

@font-face {
    font-family: 'Octarine';
    src: url('fonts/Octarine-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'OctarineBold';
    src: url('fonts/Octarine-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.login-page.main-wrap {
  overflow-y: auto;
}
.login-page .prelogin-outer-wrap {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login-page .container {
  max-width: 1182px;
  padding: 0 15px;
  margin: 0 auto;
}
.login-page .prelogin-header {
  padding: 13px 10px 13px;
  background-color: #000;
  position: relative;
  z-index: 1;
}
.login-page .prelogin-header .logo-wrap {
  margin: 0 -10px;
  display: inline-block;
}
.login-page .img-form-section {
  padding: 50px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page .img-form-section .row-wrap {
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  min-height: 500px;
  border: 1px solid #979797;
  max-width: 1040px;
  margin: 0 auto;
  width: 100%;
}
.login-page .img-form-section .row-wrap.small-width {
  max-width: 425px;
  width: 100%;
}
.login-page .left-bg-wrap {
  flex: 0 0 48%;
  max-width: 48%;
  background-color: rgba(0, 0, 0, 0.5);
  border-right: 1px solid #979797;
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
}
.login-page .left-bg-wrap .inner-wrap {
  padding: 20px 30px 50px;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.login-page .left-bg-wrap .frame-block {
  padding: 0 10px;
}
.login-page .left-bg-wrap .canvas-mode {
  color: #fff;
  font-size: 35px;
  line-height: 1.28;
  text-align: center;
  margin: 0 auto;
}
.login-page .right-content-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: rgba(35, 31, 32, 0.6);
  padding: 30px 50px 20px;
  max-width: none;
  justify-content: center;
}
.login-page .right-content-wrap::after {
  display: none;
}
.login-page .right-content-wrap .title-block {
  text-align: center;
  padding: 0 0 20px;
  margin: 0;
}
.login-page .right-content-wrap .title-block .title {
  color: #fff;
  margin: 0 0 5px;
}
.login-page .right-content-wrap .title-block h2.title {
  margin: 0;
}
.right-content-wrap .title-block .sub-title {
  font-size: 24px;
  line-height: 1.38;
  display: block;
}
.login-page .right-content-wrap .right-content-inner {
  padding: 0;
  margin: 0;
}
.login-page .right-content-wrap .pre-login-form {
  display: flex;
  flex-direction: column;
}
.login-page .right-content-wrap .btn-wrap {
  margin: 30px 0 10px;
  display: flex;
  flex-wrap: wrap;
}
.login-page .right-content-wrap .btn-wrap .btn:not(:last-child) {
  margin-right: 20px;
}
.login-page .right-content-wrap .logo-wrap-bottom {
  width: 100%;
  max-width: 275px;
  margin: 30px auto 20px;
  text-align: center;
}
.login-page .right-content-wrap .logo-wrap-bottom i {
  display: block;
}
.login-page .right-content-wrap .logo-wrap-bottom img {
  width: 100%;
}
.login-page .right-content-wrap .link-wrap {
  margin: 10px 0 20px;
}
.login-page .right-content-wrap .text-link-wrap {
  display: flex;
  margin-top: auto;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 30px 0 0;
}
.login-page .text-link-wrap.text-center {
  justify-content: center;
}
.login-page .text-link-wrap .content {
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
}
.login-page .text-link-wrap.text-center .content {
  color: #979797;
  font-size: 16px;
  line-height: 1.25;
}
.login-page .text-link-wrap.text-center .content .grey-text {
  position: relative;
  padding: 0 15px;
  color: #979797 !important;
}
.login-page .text-link-wrap.text-center .content .grey-text:after {
  content: "";
  position: absolute;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 65%;
  background-color: #979797;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.login-page .text-link-wrap.text-center .content .grey-text:last-child::after {
  display: none;
}
.login-page .text-link-wrap.text-center .primary-link {
  font-size: 16px;
  line-height: 1.25;
}
.login-page .form-group .form-select,
.login-page .form-group .form-control {
  height: 54px;
}
.login-page .form-group .password-wrap {
  position: relative;
}
.login-page .form-group .password-wrap .form-control {
  padding-right: 45px;
}
.login-page .form-group .password-wrap .show-hide-password {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 40px;
  padding: 7px;
}
